import React from 'react';
import { Text, Divider } from 'wix-ui-tpa/cssVars';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { isFreePlan } from '@wix/pricing-plans-utils';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { getOrderCoupon, getPricesCoupon, hasOrderCoupon } from '../../../../../utils/domainUtils';
import { isStartDateCustomizable } from '../../../../../utils/plan';
import { CouponInput } from '../CouponInput';
import { classes, st } from './Big.st.css';
import { DateInput } from './DateInput';
import { CheckoutSummaryPresenter } from './presenters';
import { SummaryProps } from './types';
import { useOrderSummary } from './useOrderSummary';

export const BigSummary: React.FC<SummaryProps> = ({
  locale,
  plan,
  prices = [],
  benefits,
  order,
  startDate,
  setStartDate,
  minStartDate,
  maxStartDate,
  isStartDateDisabled,
  updatePriceDetails,
  updatePriceDetailsError,
  couponInputMode,
  couponLoading,
  couponCode,
  removeCoupon,
  applyCouponError,
  hasCoupons,
}) => {
  const { t } = useTranslation();
  const presenter = new CheckoutSummaryPresenter(plan, prices, benefits, locale, t, order);
  const orderSummary = useOrderSummary(plan, prices, order);
  const { total, subtotal, taxAmount, couponAmount, planPrice, setupFee } = orderSummary;

  const isCouponsEnabled = hasCoupons && !isFreePlan(plan);
  const hasCouponOnOrder = order && hasOrderCoupon(order);
  const couponId = hasCouponOnOrder ? getOrderCoupon(order!)?.id : getPricesCoupon(prices)?.id;

  const { experiments } = useExperiments();
  const isSetupFeeEnabled = experiments.enabled(TPA_EXPERIMENTS.SETUP_FEE);
  const shouldLocalizeSessionCount = experiments.enabled(TPA_EXPERIMENTS.NUMBER_LOCALIZATION);

  const paymentDisclaimer = presenter.getPaymentDisclaimer(isSetupFeeEnabled);
  const nextPayment = presenter.getNextPayment();

  return (
    <div className={classes.box}>
      <div className={classes.section}>
        <Text className={classes.heading} tagName="h2" data-hook="payment-checkout-summary-title">
          {t('payment.checkout-summary.heading')}
        </Text>
        {isStartDateCustomizable(plan) ? (
          <DateInput
            label={t('payment.checkout-summary.start-date')}
            disabled={isStartDateDisabled}
            dataHook="start-date-input"
            value={startDate}
            max={maxStartDate}
            min={minStartDate}
            onChange={setStartDate}
          />
        ) : null}
      </div>
      {isSetupFeeEnabled ? (
        <div className={classes.sectionNarrow}>
          <div className={st(classes.entry, { noWrap: true })}>
            <Text className={classes.labelBold}>{presenter.getPlanName()}</Text>
            <Text className={classes.value}>{planPrice}</Text>
          </div>
          <Text className={classes.textLight}>{presenter.getDurationFormatted()}</Text>
          {presenter.showFreeTrial() && <Text className={classes.textLight}>{presenter.getFreeTrialFormatted()}</Text>}
          {presenter.hasSessions() && (
            <Text className={classes.textLight}>{presenter.getSessionFormatted(shouldLocalizeSessionCount)}</Text>
          )}
        </div>
      ) : (
        <div className={classes.section}>
          <div className={classes.entry}>
            <Text className={classes.label}>{t('payment.checkout-summary.plan')}</Text>
            <Text className={classes.value} data-hook="payment-checkout-summary-plan-name">
              {presenter.getPlanName()}
            </Text>
          </div>
          <div className={classes.entry}>
            <Text className={classes.label}>{t('payment.checkout-summary.duration')}</Text>
            <Text className={classes.value} data-hook="payment-checkout-summary-plan-duration">
              {presenter.getDuration()}
            </Text>
          </div>
          {presenter.hasSessions() ? (
            <div className={classes.entry}>
              <Text className={classes.label}>{t('payment.checkout-summary.sessions')}</Text>
              <Text className={classes.value} data-hook="payment-checkout-summary-plan-sessions">
                {presenter.getSessionCount(shouldLocalizeSessionCount)}
              </Text>
            </div>
          ) : null}
          {presenter.showFreeTrial() ? (
            <div className={classes.entry} data-hook="payment-checkout-summary-plan-free-trial">
              <Text className={classes.label} data-hook="payment-checkout-summary-plan-free-trial-label">
                {t('payment.checkout-summary.trial')}
              </Text>
              <Text className={classes.value} data-hook="payment-checkout-summary-plan-free-trial-days">
                {presenter.getFreeTrialDays()}
              </Text>
            </div>
          ) : null}
          {presenter.showFreeTrial() && presenter.isFreeTrialUnavailable() ? (
            <div className={classes.freeTrialDisclaimerEntry}>
              <Text
                className={classes.freeTrialDisclaimer}
                data-hook="payment-checkout-summary-plan-free-trial-disclaimer"
              >
                {t('payment.checkout-summary.trial-unavailable-disclaimer')}
              </Text>
            </div>
          ) : null}
        </div>
      )}
      {isSetupFeeEnabled && presenter.hasSetupFee() && (
        <div className={classes.section}>
          <div className={classes.entry}>
            <Text className={classes.labelBold}>{presenter.getSetupFeeName()}</Text>
            <Text className={classes.value}>{setupFee}</Text>
          </div>
        </div>
      )}
      {isCouponsEnabled ? (
        <>
          <Divider className={classes.divider} />
          <CouponInput
            disabled={hasCouponOnOrder || isStartDateDisabled}
            mode={hasCouponOnOrder ? 'coupon' : couponInputMode}
            error={updatePriceDetailsError ?? applyCouponError}
            value={hasCouponOnOrder ? getOrderCoupon(order!)!.code! : couponCode}
            onApplyClick={(code) => updatePriceDetails(plan.id!, code)}
            onRemoveClick={() => removeCoupon(plan.id!, couponId!)}
            loading={couponLoading}
          />
        </>
      ) : null}
      {((taxAmount || presenter.hasCoupon()) && subtotal) ||
      (presenter.hasCoupon() && subtotal) ||
      (taxAmount && subtotal) ? (
        <>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            {(taxAmount || presenter.hasCoupon()) && subtotal ? (
              <div className={classes.entry} data-hook="payment-checkout-summary-subtotal-row">
                <Text className={classes.label}>{t('payment.checkout-summary.subtotal')}</Text>
                <Text className={classes.value} data-hook="payment-checkout-summary-subtotal">
                  {subtotal}
                </Text>
              </div>
            ) : null}
            {presenter.hasCoupon() && subtotal ? (
              <div className={classes.entry} data-hook="payment-checkout-summary-coupon-discount-row">
                <Text className={classes.label}>{t('payment.checkout-summary.coupon-code')}</Text>
                <Text className={classes.value} data-hook="payment-checkout-summary-coupon-discount">
                  {couponAmount}
                </Text>
              </div>
            ) : null}
            {taxAmount && subtotal ? (
              <div className={classes.entry} data-hook="payment-checkout-summary-tax-row">
                <Text className={classes.label} data-hook="payment-checkout-summary-tax-label">
                  {presenter.getTaxName()} ({presenter.getTaxRate()} %)
                </Text>
                <Text className={classes.value} data-hook="payment-checkout-summary-tax">
                  {taxAmount}
                </Text>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      <Divider className={classes.divider} />
      <div className={classes.section}>
        <div className={classes.entry}>
          <Text
            className={st(classes.labelLarge, { setupFeeEnabled: isSetupFeeEnabled })}
            data-hook="payment-checkout-summary-plan-total"
          >
            {presenter.getTotalLabel(isSetupFeeEnabled)}
          </Text>
          <Text
            className={st(classes.valueLarge, { setupFeeEnabled: isSetupFeeEnabled })}
            data-hook="payment-checkout-summary-plan-price"
          >
            {total}
          </Text>
        </div>
        {presenter.showPaymentPeriod(isSetupFeeEnabled) ? (
          <div className={classes.paymentPeriodEntry} data-hook="payment-checkout-summary-payment-period">
            <Text className={classes.paymentPeriod}>{presenter.getPaymentPeriod()}</Text>
          </div>
        ) : null}
        {isSetupFeeEnabled && nextPayment && (
          <div className={classes.entry}>
            <Text className={classes.label}>{nextPayment.label}</Text>
            <Text className={classes.value}>{nextPayment.amount}</Text>
          </div>
        )}
        {paymentDisclaimer ? (
          <div className={classes.entry} data-hook="payment-checkout-summary-payment-disclaimer">
            <Text className={classes.value}>{paymentDisclaimer}</Text>
          </div>
        ) : null}
      </div>
    </div>
  );
};
